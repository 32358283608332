import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CHART_ASPECT_RATIO, LINE_WIDTH_0, LINE_WIDTH_2 } from "../constants";
import { DomainContext, RangeContext } from "../contexts";
const AXIS_LABEL_COUNT = 6;
export default function LineChart({ centerXAxisLabels = false, children, domain, points, range, xAxisLabels = [...Array(AXIS_LABEL_COUNT).keys()].map((n) => String(Math.round((n / (AXIS_LABEL_COUNT - 1)) * (domain[1] - domain[0]) + domain[0]))), xAxisTitle, yAxisLabels = [...Array(AXIS_LABEL_COUNT).keys()].map((n) => String(Math.round((n / (AXIS_LABEL_COUNT - 1)) * (range[1] - range[0]) + range[0]))), yAxisTitle, ...rest }) {
    const pointsWithinDomain = points?.filter(({ x }) => x >= domain[0] && x <= domain[1]);
    return (_jsxs("div", { ...rest, className: `line-chart${centerXAxisLabels ? " line-chart--centered" : ""}`, children: [_jsx("div", { className: "line-chart__y-title fade-in nowrap", children: yAxisTitle }), _jsx("div", { className: "line-chart__y-axis", style: { "--label-count": yAxisLabels.length }, children: yAxisLabels.map((yLabel, i) => (_jsx("div", { className: "line-chart__y-label fade-in", style: { "--label-number": i }, children: yLabel }, yLabel))) }), _jsxs("div", { className: "line-chart__plot-area", children: [pointsWithinDomain?.length ? (_jsx(_Fragment, { children: pointsWithinDomain.map(({ color = "var(--color-theme)", x, y }, i) => (_jsx("div", { className: "line-chart__point", style: {
                                "--point-index": i,
                                "--total-points": pointsWithinDomain.length,
                                backgroundColor: color,
                                bottom: `calc(${(y - range[0]) / (range[1] - range[0])} * (100% - var(--border-width-1)) - var(--point-diameter) / 2 + var(--border-width-1))`,
                                left: `calc(${(x - domain[0]) / (domain[1] - domain[0])} * (100% - var(--border-width-1)) - var(--point-diameter) / 2 + var(--border-width-1))`,
                            } }, [x, y].join(":")))) })) : undefined, _jsxs("svg", { className: "line-chart__svg", viewBox: `0 0 ${CHART_ASPECT_RATIO} 1`, children: [[...Array(xAxisLabels.length + Number(centerXAxisLabels)).keys()]
                                .map((i) => (i * CHART_ASPECT_RATIO) /
                                (xAxisLabels.length - Number(!centerXAxisLabels)))
                                .map((x) => (_jsx("line", { stroke: "var(--color-balance)", strokeDasharray: LINE_WIDTH_2, strokeWidth: LINE_WIDTH_0, x1: x, x2: x, y1: 0, y2: 1 }, x))), yAxisLabels
                                .map((_, i) => i / (yAxisLabels.length - 1))
                                .map((y) => (_jsx("line", { stroke: "var(--color-balance)", strokeDasharray: LINE_WIDTH_2, strokeWidth: LINE_WIDTH_0, x1: 0, x2: CHART_ASPECT_RATIO, y1: y, y2: y }, y))), _jsx("defs", { children: _jsx("clipPath", { id: "plot-area-clip-path", children: _jsx("rect", { x: 0, y: 0, height: 1, width: CHART_ASPECT_RATIO }) }) }), _jsx(DomainContext, { value: domain, children: _jsx(RangeContext, { value: range, children: _jsx("g", { clipPath: "url(#plot-area-clip-path)", children: children }) }) })] })] }), _jsx("div", { className: "line-chart__x-axis", style: { "--label-count": xAxisLabels.length }, children: xAxisLabels.map((xLabel, i) => (_jsx("div", { className: `line-chart__x-label fade-in${centerXAxisLabels ? " line-chart__x-label--centered" : ""}`, style: { "--label-number": i }, children: xLabel }, xLabel + i))) }), _jsx("div", { className: "line-chart__x-title fade-in nowrap", children: xAxisTitle })] }));
}
